<template>
  <section id="list-double-registration">
    <b-breadcrumb
      :items="breadcrumb"
    />

    <b-row>
      <b-col cols="12">
        <b-card-actions
          ref="filters"
          title="Filtros"
          class="form-filters"
          :action-close="false"
          :action-refresh="false"
          :action-collapse="true"
          @refresh="refreshStop('cardAction')"
        >
          <b-row>
            <b-col cols="3">
              <b-form-group
                label="DDD"
                label-for="ddd"
              >
                <b-form-textarea
                  id="ddd"
                  v-model="filtersValues.ddd"
                />
                <small>{{ user.cell_phone }}</small><br>
                <small>{{ user.phones ? user.phones.join(', ') : '' }}</small>
              </b-form-group>
            </b-col>

            <b-col cols="3">
              <b-form-group
                label="Telefones"
                label-for="phone"
              >
                <b-form-textarea
                  id="phone"
                  v-model="filtersValues.phone"
                  rows="3"
                />
              </b-form-group>
            </b-col>

            <b-col cols="3">
              <b-form-group
                label="E-mails"
                label-for="email"
              >
                <b-form-textarea
                  id="email"
                  v-model="filtersValues.email"
                  rows="3"
                />
                <small>{{ user.email }}</small>
              </b-form-group>
            </b-col>

            <b-col cols="3">
              <b-form-group
                label="Site"
                label-for="site"
              >
                <b-form-textarea
                  id="site"
                  v-model="filtersValues.site"
                  rows="3"
                />
                <small>{{ sites.join(' / ') }}</small>
              </b-form-group>
            </b-col>

            <b-col cols="3">
              <b-form-group
                label="Nome, Empresa e Apresentação"
                label-for="company"
              >
                <b-form-textarea
                  id="company"
                  v-model="filtersValues.company"
                  rows="3"
                />
                <small>{{ user.company_profile !== undefined ? user.company_profile.company_name : '' }}</small>
              </b-form-group>
            </b-col>

            <b-col cols="3">
              <b-form-group
                label="Nome"
                label-for="name"
              >
                <b-form-textarea
                  id="name"
                  v-model="filtersValues.name"
                  rows="3"
                />
                <small>{{ user.name }}</small>
              </b-form-group>
            </b-col>

            <b-col
              cols="6"
              class="form-buttons"
            >
              <b-form-group
                label=""
                label-for="btn-buscar"
                style="margin-top: 20px"
              >
                <b-button
                  id="btn-buscar"
                  variant="primary"
                  :disabled="isLoading"
                  @click="getDoubleRegistrations()"
                >
                  Buscar
                </b-button>

                <b-button
                  id="btn-limpar"
                  variant="outline-secondary"
                  @click="reset()"
                >
                  Limpar
                </b-button>
              </b-form-group>
            </b-col>
          </b-row>
          <div
            v-if="managerData.permission_type === 'root'"
            class="text-right"
            style="margin-right: -10px; margin-bottom: -15px;"
            @click="changeOrigin"
          >
            <span class="small">Origem: {{ origin }}</span>
          </div>
        </b-card-actions>

        <b-card>
          <b-row
            class="header-card"
            align-v="center"
          >
            <b-col>
              <h4>
                Resultados
              </h4>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12">
              <b-table
                :busy="isLoading"
                responsive
                :items="results"
                :fields="result_fields"
                sort-icon-left
                no-local-sorting
                :sort-by.sync="filtersValues.sortBy"
                :sort-desc.sync="filtersValues.sortDesc"
                @sort-changed="sortingChanged"
              >
                <template #table-busy>
                  <div class="text-center table-spinner">
                    <b-spinner label="Loading..." />
                    <strong>Carregando...</strong>
                  </div>
                </template>

                <template #head(status)>
                  <div class="text-nowrap">
                    Status
                  </div>
                </template>

                <template #head(type)>
                  <div class="text-nowrap">
                    Tipo
                  </div>
                </template>

                <template #head(company_name)>
                  <div class="text-nowrap">
                    Empresa
                  </div>
                </template>

                <template #head(status)>
                  <div class="text-nowrap">
                    Status
                  </div>
                </template>

                <template #head(matches)>
                  <div class="text-nowrap">
                    Encontrado em
                  </div>
                </template>

                <template #head(name)>
                  <div class="text-nowrap">
                    Nome
                  </div>
                </template>

                <template #head(email)>
                  <div class="text-nowrap">
                    E-mails
                  </div>
                </template>

                <template #head(phones)>
                  <div class="text-nowrap">
                    Telefones
                  </div>
                </template>

                <template #head(actions)>
                  <div class="text-nowrap text-center">
                    Ações
                  </div>
                </template>

                <template #cell(type)="row">
                  {{ $t(`users.columns.type.${row.item.type}`) }}
                </template>

                <template #cell(name)="row">
                  <!-- eslint-disable vue/no-v-html -->
                  <span v-html="row.item.name" />
                </template>

                <template #cell(id)="row">
                  <b-link :to="{ name: 'cms-user-form-update', params: { id: row.item.id } }">
                    {{ row.item.id }}
                  </b-link>
                </template>

                <template #cell(email)="row">
                  {{ row.item.email }}<br>
                  <span
                    v-for="email in row.item.emails"
                    :key="row.item.id + '' + email"
                  >
                    {{ email }}
                  </span>
                  <span
                    v-for="email_cms in row.item.emails_cms"
                    :key="row.item.id + '' + email_cms"
                  >
                    {{ email_cms }}
                  </span>
                </template>

                <template #cell(phones)="row">
                  {{ row.item.cell_phone }}<br>
                  <span
                    v-for="phone in row.item.phones"
                    :key="row.item.id + '' + phone"
                  >
                    {{ phone }}
                  </span>
                  <span
                    v-for="phone_cms in row.item.phones_cms"
                    :key="row.item.id + '' + phone_cms"
                  >
                    {{ phone_cms }}
                  </span>
                </template>

                <template #cell(matches)="row">
                  {{ row.item.matches.join(', ') }}
                </template>

                <template #cell(status)="row">
                  {{ $t(`users.columns.status.${row.item.status}`) }}
                </template>

                <template #cell(created_at)="row">
                  {{ formatDateTimeDbToView(row.item.created_at) }}
                </template>

              </b-table>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="6">
              <p>Mostrando {{ pages.from }} até {{ pages.to }} de {{ pages.total }} resultados</p>
            </b-col>

            <b-col cols="6">
              <!-- pagination nav -->
              <b-pagination-nav
                v-model="currentPage"
                :number-of-pages="pages.last_page"
                base-url="#"
                align="end"
              />
            </b-col>
          </b-row>
        </b-card>
      </b-col>

    </b-row>

  </section>
</template>

<script>
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import UserService from '@/services/userService'
import {
  userTypes,
} from '@/utils/options'
import { formatDateTimeDbToView, removeKnownDomains } from '@/utils/helpers'
// import moment from 'moment'
import {
  BRow,
  BCol,
  BTable,
  BLink,
  // BFormInput,
  BFormTextarea,
  BFormGroup,
  // BFormSelect,
  BPaginationNav,
  BSpinner,
  BButton,
  // BModal,
  BCard,
  BBreadcrumb,
} from 'bootstrap-vue'
// import vSelect from 'vue-select'

export default {
  components: {
    BCardActions,
    BCard,
    BRow,
    BCol,
    BTable,
    BLink,
    // BFormInput,
    BFormTextarea,
    BFormGroup,
    // BFormSelect,
    BPaginationNav,
    BSpinner,
    BButton,
    // BModal,
    BBreadcrumb,
    // vSelect,
  },

  data() {
    return {
      managerData: {
        permission_type: null,
      },
      origin: 'Automático',
      userService: null,
      isLoading: true,
      breadcrumb: [
        {
          text: 'Dashboard',
          to: { name: 'cms-dashboard' },
        },
        {
          text: 'Usuários',
          to: { name: 'cms-user' },
        },
        {
          text: 'Duplo',
          active: true,
        },
      ],

      user: {},
      results: [],
      sites: [],

      filtersInitialValues: {},
      filtersValues: {
        ddd: '',
        phone: '',
        email: '',
        site: '',
        company: '',
        name: '',
        sortBy: 'id',
        sortDesc: true,
      },

      pages: 1,
      currentPage: 1,
      result_fields: [],
      formatDateTimeDbToView,
      userTypes,
    }
  },

  watch: {
    currentPage() {
      this.getDoubleRegistrations()
    },
  },

  async created() {
    this.userService = new UserService()

    const managerDataString = await localStorage.getItem('userData')
    this.managerData = JSON.parse(managerDataString)

    this.result_fields = [
      { key: 'id', sortable: true },
      { key: 'matches', sortable: true },
      { key: 'type', sortable: true },
      { key: 'status', sortable: true },
      { key: 'name', sortable: true },
      { key: 'company_name', sortable: true },
      { key: 'email', sortable: true },
      { key: 'phones', sortable: true },
    ]
    this.filtersInitialValues = { ...this.filtersValues }

    this.isLoading = true
    await this.getUser(this.$route.params.id)
    await this.getDoubleRegistrations()
  },

  methods: {
    changeOrigin() {
      if (this.origin === 'ElasticSearch') {
        this.origin = 'MySql'
      } else {
        this.origin = 'ElasticSearch'
      }
    },
    bold(str) {
      let ret = ''
      if (str == null) {
        return ''
      }

      const regEx = this.filtersValues.name.split('\n').filter(f => f !== '*').join('|')
      ret = str.replace(new RegExp(`(${regEx})`, 'gi'), '<span class="text-danger">$1</span>')
      return ret
    },

    getUser() {
      return this.userService.getAllInformations(this.$route.params.id).then(response => {
        if (response.status === 200) {
          this.user = response.data.data.user

          this.sites = [this.user.profile.link_site ?? '',
            this.user.profile.link_facebook ?? '',
            this.user.profile.link_instagram ?? '',
            this.user.profile.link_linkedin ?? '',
          ].filter(f => f.trim() !== '')

          let phones = [this.user.cell_phone ?? '', ...this.user.phones]
          // filter para eliminar os vazios,
          // map para pegar somente os 2 primeiros digitos(ddd)
          // new Set para eliminar os duplicados
          const ddds = [...new Set(phones.map(m => {
            const match = m.replace(/[^0-9]*/g, '')
            if (match.length > 1) {
              return match.substr(0, 2)
            }
            return ''
          }).filter(f => f.trim() !== ''))]

          phones = phones.map(m => {
            const match = m.replace(/[^0-9]*/g, '')
            return match.substr(2)
          }).filter(f => f.trim() !== '')

          this.filtersValues = {
            type: this.user.type,
            ddd: ddds.join('\n'),
            phone: phones.join('\n'),
            name: this.user.name.split(' ').join('\n'),
            company: (this.user.profile?.company_name !== undefined && this.user.profile?.company_name) ? this.user.profile.company_name.split(' ').join('\n') : '',
            email: this.user.all_emails.split(',').map(removeKnownDomains).join('\n'),
            site: this.sites.map(m => m.replace(new RegExp('http://|https://|www.|.com|.br|.net|.edu|instagram|linkedin|facebook', 'gi'), '')
              .replace(new RegExp('(.*)/$', 'gi'), '$1')
              .replace(new RegExp('^/(.*)', 'gi'), '$1')).join('\n'),
          }
        }
      })
    },

    getDoubleRegistrations() {
      this.isLoading = true
      const data = {
        user_type: this.filtersValues.type,
        ddd: this.filtersValues.ddd.split('\n').filter(f => f.trim() !== ''),
        name: this.filtersValues.name.split('\n').filter(f => f.trim() !== ''),
        company_name: this.filtersValues.company.split('\n').filter(f => f.trim() !== ''),
        email: this.filtersValues.email.split('\n').filter(f => f.trim() !== ''),
        phones: this.filtersValues.phone.split('\n').filter(f => f.trim() !== ''),
        links: this.filtersValues.site.split('\n').filter(f => f.trim() !== ''),
        page: this.currentPage,
        sortBy: this.filtersValues.sortBy,
        sortDesc: this.filtersValues.sortDesc,
      }
      if (this.origin === 'ElasticSearch') {
        data.force_elastic = 1
      }
      if (this.origin === 'MySql') {
        data.force_mysql = 1
      }
      this.userService.getDoubleRegistrations(data).then(response => {
        this.results = response.data.data.users.data.map(item => ({
          ...item,
          name: this.bold(item.name),
          matches: item.matches.map(match => this.$t(`doubleRegistration.columns.matches.${match}`)),
        }))

        this.results.sort(a => {
          if (parseInt(a.id, 10) === parseInt(this.$route.params.id, 10)) {
            return -1
          }
          return 0
        })

        this.pages = { ...response.data.data.users, data: null }
        this.isLoading = false
      })
    },

    reset() {
      this.filtersValues = JSON.parse(JSON.stringify(this.filtersInitialValues))
      this.getDoubleRegistrations()
    },

    sortingChanged(ctx) {
      this.filtersValues.sortBy = ctx.sortBy
      this.filtersValues.sortDesc = ctx.sortDesc
      this.getDoubleRegistrations()
    },
  },
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';

  #list-double-registration .b-table td{
    padding: 0.72rem;
  }

  .header-card{
    margin-bottom: 20px;
  }
</style>
